<template>
  <div id="SOHUCS" :sid="id"></div>
</template>

<script>
import init from "@/utils/index";
import initData from "@/utils/comment";
export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  mounted() {
    init();
    initData();
  },
  destroyed() {
    window.changyan = undefined;
    window.cyan = undefined;
  },
};
</script>

<style>
</style>