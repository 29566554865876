<template>
  <div class="detail">
    <div class="article pd20">
      <!-- 文章信息 -->
      <div class="article_info">
        <h4>{{ article.title }}</h4>
        <div class="info">
          <span class="author">
            <i class="el-icon-s-custom"></i>
            <span>作者：{{ article.author }}</span>
          </span>
          |
          <span class="tags">
            <i class="el-icon-price-tag"></i>
            <span
              >标签：<a @click="getArticleByTags(article.tags)">{{
                article.tags
              }}</a></span
            >
          </span>
          |
          <span class="time">
            <i class="el-icon-time"></i>
            <span>发布于：{{ article.createdAt }}</span>
          </span>
          |
          <span class="views">
            <i class="el-icon-view"></i>
            <span>浏览量：{{ article.views }}</span>
          </span>
        </div>
      </div>
      <!-- 正文 -->
      <div class="description">
        <div class="text" v-html="article.content"></div>
        <div class="options">
          <p v-if="!!last">
            上一篇：<router-link tag="span" :to="`/detail/${last.id}`">{{
              last.title
            }}</router-link>
          </p>
          <p v-if="!!next">
            下一篇：<router-link tag="span" :to="`/detail/${next.id}`">{{
              next.title
            }}</router-link>
          </p>
        </div>
      </div>
    </div>

    <div class="comment mt20 pd20" v-if="show">
      <chang-yan :id="id"></chang-yan>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import article from "@/api/article";
import ChangYan from "./base/ChangYan.vue";
export default {
  data() {
    return {
      id: "",
      article: {},
      last: "",
      next: "",
      show: true,
    };
  },
  components: {
    ChangYan,
  },
  watch: {
    $route(newVal) {
      this.show = false;
      const id = newVal.params.id;
      this.initData(id);
      setTimeout(() => {
        this.show = true;
      }, 1000);
    },
  },
  created() {
    this.initData();
  },
  methods: {
    ...mapActions(["toDetail", "getArticleByTags"]),
    ...mapMutations(["handleScrollTop"]),
    initData(id) {
      id = id || this.$route.params.id;
      this.id = +id;
      this.getArticleDetail(id);
      this.handleScrollTop(); // 初始化滚动条
    },
    async getArticleDetail(id) {
      const res = await article.getArticleDetail(id);
      const current = res.filter((item) => item.id == id);
      const last = res.filter((item) => item.id < id);
      const next = res.filter((item) => item.id > id);
      if (current.length > 0) {
        // 有当前文章
        this.article = current[0]; //就赋值
        // 上一篇有文章
        this.last = last[0];
        this.next = next[0];
        this.addViews(current[0]);
      } else {
        // 没有这篇文章，需要跳转到404页面
      }
    },
    async addViews(val) {
      const params = {
        views: val.views + 1,
        id: val.id,
      };
      try {
        await article.updateArticle(params);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="less">
.detail {
  width: 100%;
  .article {
    background-color: #fff;
    border-radius: 5px;
    font-size: 14px;

    .article_info {
      padding-bottom: 10px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);

      h4 {
        font-size: 22px;
      }
      .info {
        height: 40px;
        line-height: 40px;
        span i {
          margin-right: 5px;
        }
        .tags a:hover {
          color: #58a6e7;
          cursor: pointer;
        }
      }
    }
    .text {
      padding-top: 20px;
    }
    .options {
      margin-top: 50px;
      p {
        height: 40px;
        line-height: 40px;

        span {
          display: inline-block;
          margin-left: 5px;
          padding: 0 15px;
          height: 30px;
          line-height: 30px;
          background-color: #cce8cf;
          border-radius: 15px;

          &:hover {
            color: #58a6e7;
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>